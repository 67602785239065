.saleBox {
    display: flex;
    justify-content: space-between;
}

.leftBox {
    width: 70%;
}

.rightBox {
    width: 25%;
    height: 85vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #FFF;
    padding: 20px;
}

.memberBox {
    padding: 20px 20px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 57px -11px rgba(224,224,224,1);
    background: #FFF;
}

.productBox {
    height: calc(100% - 122px);
    padding-top: 20px;
    box-shadow: 0px 0px 57px -11px rgba(224,224,224,1);
    background: #FFF;
}

.memberBox .saleSearchBox {
    display: flex;
    align-items: center;
    width: 100%;
}

.memberBox .saleSearchBox div input {
    width: 30%;
    margin: 0 10px;
}

.conditionProductBox {
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chargeMoneyBox {
    margin: 20px 0;
}

.formInputTotal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    height: 120px;
}

.formInputTotal label {
    font-size: 2em;
}

.formInputTotal input {
    height: 70px;
    color: #000 !important;
    background-color: #FFF !important;
    border-color: #000099 !important;
    font-size: 2em;
    text-align: center;
    font-weight: bold;
}

.btn-sale-menu-box {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.btn-sale-menu-box button {
    width: 48%;
}

.workShiftContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
}

.workShiftContainer :where(.css-dev-only-do-not-override-fpg3f5).ant-checkbox-wrapper {
    font-size: 16px;
}

.workShiftContainer table {
    width: 100%;
    margin-top: 20px;
}

.workShiftContainer table td {
    padding: 5px 0;
}

.workShiftContainer table input {
    width: 80%;
}

.workShiftContainerTotal {
    width: 100%;
    margin-top: 20px;
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #ececec;
}

.workShiftContainerTotal div {
    width: 40%;
}

.workShiftContainerTool {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.workShiftContainerTool button {
    margin: 0 5px;
}

.shortcutContainer, .configShortcutContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
/* :not(:first-of-type) */
.shortcutContainer div, .configShortcutContainer .configShortcutContainerDetail {
    padding: 10px;
    margin: 10px 0;
    width: 20%;
    height: 70px;
    font-size: 14px;
    border: 2px solid #000099;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.shortcutContainer div, .shortcutContainer label {
    cursor: pointer;
}

.configShortcutContainerDetail {
    height: 110px !important;
}

.configShortcutContainerDetail span {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.configShortcutContainerDetail span button {
    margin-left: 5px;
}

.configShortcutContainerDetail .icon-config {
    font-size: 14px !important;
    padding: 1px 9px !important;
    height: 26px !important;
}