
#mouse-scroll {
    style: block;
}

#mouse-scroll {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
}

#mouse-scroll span{
    display: block;
    width: 10px; 
    height: 10px;
    -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
            transform: rotate(45deg);
    border-right: 2px solid #000; 
    border-bottom: 2px solid #000;
    margin: 0 0 3px 5px;
}

#mouse-scroll .mouse {
    height: 21px;
    width: 14px;
    border-radius: 10px;
    -webkit-transform: none;
    transform: none;
    border: 2px solid #000;
    top: 170px;
}

#mouse-scroll .down-arrow-1 {
    margin-top: 6px;
}

#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}

#mouse-croll .down-arrow-1 {
    -webkit-animation-delay: .1s; 
    -moz-animation-delay: .1s;
    -webkit-animation-direction: alternate;
}

#mouse-scroll .down-arrow-2 {
    -webkit-animation-delay: .2s; 
    -moz-animation-delay: .2s;
    -webkit-animation-direction: alternate;
}

#mouse-scroll .down-arrow-3 {
    -webkit-animation-delay: .3s;
    -moz-animation-delay: .3s;
    -webkit-animation-direction: alternate;
}

#mouse-scroll .mouse-in {
    height: 5px;
    width: 2px;
    display: block; 
    margin: 5px auto;
    background: #000;
    position: relative;
}

#mouse-scroll .mouse-in {
    -webkit-animation: animated-mouse 1.2s ease infinite;
    moz-animation: mouse-animated 1.2s ease infinite;
}

@-webkit-keyframes animated-mouse {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(6px);
        transform: translateY(6px);
    }
}

@-webkit-keyframes mouse-scroll {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    } 
}
@keyframes mouse-scroll {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}