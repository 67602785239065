.loginContainer {
    width: 100vw;
    height: 100vh;
    background: #3366FF;
    position: relative;
}

.logoBox {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.logoBox div {
    width: 50%;
}

.logoBox .loginInput {
    position: relative;
    width: 50%;
    height: 30%;
    background: #fffc;
    border-radius: 20px;
    padding: 7% 5% 2% 5%;
    text-align: left;
    box-shadow: 10px 10px 35px -6px rgba(108,69,75,1);
}

.logoBox .loginInput div {
    width: 100%;
}

.logoBox .loginInput .logoBoxInput {
    margin-top: 20px;
}

.logoBox .loginLogo {
    text-align: center;
}

.logoBox .loginLogo img {
    width: 50%;
    margin: 2% 0;
}

.userLoginIcon {
    position: absolute;
    top: -60px;
    left: 50%;
    font-size: 4em;
    color: #FFF;
    padding: 5% 6%; 
    background: #1d42af;
    border-radius: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

@media only screen and (max-width: 760px) {
    .logoBox {
        display: flex;
        position: absolute;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-evenly;
    }
    
    .logoBox div:first-child {
        width: 90%;
    }
}