:root {
    --rmdp-primary-custom: #000099;
    --rmdp-secondary-custom: #263a4e;
    --rmdp-shadow-custom: #3c5166;
    --rmdp-today-custom: #537597;
    --rmdp-hover-custom: #122a42;
    --rmdp-deselect-custom: #0d2236;
}

.rmdp-input {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    padding: 4px 11px;
    background-color: #ffffff;
    background-image: none;
    font-size: 14px;
}


.fromInputDatetime .rmdp-wrapper {
    border: 1px solid #263a4e;
    border: 1px solid var(--rmdp-secondary-custom);
    box-shadow: 0 0 5px #263a4e;
    box-shadow: 0 0 5px var(--rmdp-secondary-custom);
}

.fromInputDatetime .rmdp-panel-body li {
    background-color: #000099;
    background-color: var(--rmdp-primary-custom);
    box-shadow: 0 0 2px #263a4e;
    box-shadow: 0 0 2px var(--rmdp-secondary-custom);
}

.fromInputDatetime .rmdp-week-day {
    color: #000099;
    color: var(--rmdp-primary-custom);
}

.fromInputDatetime .rmdp-day.rmdp-deactive {
    color: #263a4e;
    color: var(--rmdp-secondary-custom);
}

.fromInputDatetime .rmdp-range {
    background-color: #000099;
    background-color: var(--rmdp-primary-custom);
    box-shadow: 0 0 3px #3c5166;
    box-shadow: 0 0 3px var(--rmdp-shadow-custom);
}

.fromInputDatetime .rmdp-arrow {
    border: solid #000099;
    border: solid var(--rmdp-primary-custom);
    border-width: 0 2px 2px 0;
}

.fromInputDatetime .rmdp-arrow-container:hover {
    background-color: #000099;
    background-color: var(--rmdp-primary-custom);
    box-shadow: 0 0 3px #263a4e;
    box-shadow: 0 0 3px var(--rmdp-secondary-custom);
}

.fromInputDatetime .rmdp-panel-body::-webkit-scrollbar-thumb {
    background: #000099;
    background: var(--rmdp-primary-custom);
}

.fromInputDatetime .rmdp-day.rmdp-today span {
    background-color: #537597;
    background-color: var(--rmdp-today-custom);
}

.fromInputDatetime .rmdp-rtl .rmdp-panel {
    border-left: 0;
    border-left: initial;
    border-right: 1px solid #263a4e;
    border-right: 1px solid var(--rmdp-secondary-custom);
}

.fromInputDatetime .rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: #000099;
    background-color: var(--rmdp-primary-custom);
    box-shadow: 0 0 3px #3c5166;
    box-shadow: 0 0 3px var(--rmdp-shadow-custom);
}

.fromInputDatetime .rmdp-day:not(.rmdp-day-hidden) span:hover {
    background-color: #122a42 !important;
    background-color: var(--rmdp-hover-custom) !important;
}

.fromInputDatetime .b-deselect {
    color: #0d2236;
    color: var(--rmdp-deselect-custom);
    background-color: white;
}

.fromInputDatetime .rmdp-action-button {
    color: #000099;
    color: var(--rmdp-primary-custom);
}

.fromInputDatetime .rmdp-button:not(.rmdp-action-button) {
    background-color: #000099;
    background-color: var(--rmdp-primary-custom);
}

.fromInputDatetime .rmdp-button:not(.rmdp-action-button):hover {
    background-color: #0d2236;
    background-color: var(--rmdp-deselect-custom);
}
