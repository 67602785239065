/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@font-face {
  font-family: "Kodchasan";
  font-style: normal;
  font-weight: normal;
  src: local("Kodchasan"), url("./assets/fonts/Kodchasan-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Kodchasan";
  font-style: normal;
  font-weight: bold;
  src: local("Kodchasan"), url("./assets/fonts/Kodchasan-Regular.ttf") format("truetype");
}

.App {
  width: 100%;
  height: 100%;
  font-family: 'Kodchasan', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.content-container {
  padding: 1.5% 1.5% 0 1.5%;
}

.btn-common, .btn-save, .ant-btn-primary {
  border: 1px solid #000099 !important;
  background: #000099 !important;
  color: #FFF !important;
  width: 120px;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.btn-cancel, .btn-back, .ant-modal-confirm-btns .ant-btn-default, .btn-cancel-sale {
  border: 1px solid #000099 !important;
  background: #FFF !important;
  color: #000099 !important;
  width: 120px;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.btn-cancel-sale {
  border: 1px solid red !important;
  color: red !important;
}

.btn-common, .btn-back {
  width: auto;
}

.formInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.formInput input:not(.ant-input-number-input):not(.rmdp-input), .formInput textarea, .formInput .ant-select, .formInput .ant-input-password, .formInput div:not(.formInputDate div, .formInputRadio div, .formInputCheckbox div, .ant-select-selection-overflow-item), .formInput .ant-input-affix-wrapper {
  width: 65%;
}

.formInput .halfInput {
  display: flex;
  justify-content: space-between;
}

.formInput .halfInput span {
  color: #000 !important;   
}

.formInput .halfInput div {
  text-align: right;  
}

.formInput .ant-input-password .ant-input {
  width: 100%;
}

.formInput label:not(.formInputRadio label, .formInputCheckbox label) {
  text-align: left;
  max-width: 30%;
}

.formInput label span:not(.formInputRadio span, .formInputCheckbox span) {
  color: red;
}

.formInputRadio {
  padding: 4px 0;
}

.formInputCheckbox {
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formInputDataTable {
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formInputDataTable .btn-common {
  width: 45px;
}

.headerBox {
  display: flex;
  justify-content: space-between;
  width: 100%;
} 

.headerBox div:not(.formInput) {
  width: 48%;
}

.headerBox .formInput label:not(.formInputRadio label, .formInputCheckbox label) {
  text-align: right;
  max-width: 25%;
}

.headerBox .formInput label:not(.formInputRadio label, .formInputCheckbox label), .headerBox .formInput input:not(.ant-input-number-input):not(.rmdp-input), .headerBox .formInput textarea, .headerBox .formInput .ant-select, .headerBox .formInput .ant-input-password, .headerBox .formInput div:not(.formInputDate div, .formInputRadio div, .formInputCheckbox div), .headerBox .formInput .ant-input-affix-wrapper {
  width: 70%;
  margin-right: 10px;
}

.searchDataBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.searchDataBox input {
  width: 30%;
  margin: 0 10px;
}

.footerBox {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 55px;
  background: #FFF;
  z-index: 3;
}

.productFrom, .productTo {
  border: 1px solid #ececec;
  padding: 40px 20px 20px;
  position: relative;
}

.productFrom .title, .productTo .title {
  position: absolute;
  top: -20px;
  left: 20px;
  padding: 10px;
  background: #FFF;
}