/* menu */

.ant-menu, .ant-dropdown, .ant-dropdown-menu, .ant-btn, .ant-input, .ant-table-wrapper .ant-table, .ant-drawer, .rmdp-input, .ant-radio, .ant-radio-wrapper, .ant-checkbox, .ant-checkbox-wrapper,
.ant-select-selector, .ant-select-selection-search, .ant-select-selection-item, .ant-select-selection-search-input, .ant-select-item, .ant-select-item-option, .ant-select-item-option-selected, .ant-modal-content {
    font-family: 'Kodchasan', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.ant-menu, .ant-dropdown, .ant-dropdown-menu {
    font-size: 1em;
}

.ant-menu-overflow-item {
    margin: 0 10px !important;
}

.ant-menu-submenu-placement-bottomRight, .ant-menu-submenu-placement-bottomLeft {
    padding-top: 15px;
}

.ant-menu-dark, .ant-menu-dark>.ant-menu {
    background: transparent;
}

.ant-menu-dark.ant-menu-horizontal >.ant-menu-item-selected, .ant-menu-dark>.ant-menu.ant-menu-horizontal >.ant-menu-item-selected, .ant-menu-dark.ant-menu-horizontal >.ant-menu-submenu-selected, .ant-menu-dark>.ant-menu.ant-menu-horizontal >.ant-menu-submenu-selected {
    background-color: #000099;
}

.ant-menu-dark.ant-menu-horizontal >.ant-menu-item-selected:hover, .ant-menu-dark>.ant-menu.ant-menu-horizontal >.ant-menu-item-selected:hover, .ant-menu-dark.ant-menu-horizontal >.ant-menu-submenu-selected:hover, .ant-menu-dark>.ant-menu.ant-menu-horizontal >.ant-menu-submenu-selected:hover {
    background-color: #000099;
}

.ant-menu-dark.ant-menu-submenu>.ant-menu, .ant-menu-dark>.ant-menu.ant-menu-submenu>.ant-menu {
    background-color: #3366FF;
}

.ant-menu-dark .ant-menu-item-selected, .ant-menu-dark>.ant-menu .ant-menu-item-selected {
    background-color: #000099;
}

.anticon {
    align-items: center;
    font-size: 1.2em !important;
}

.ant-dropdown .ant-dropdown-menu, .ant-dropdown-menu-submenu .ant-dropdown-menu {
    background-color: #3366FF;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item, .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item, .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title, .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
    color: #FFF;
}

.ant-dropdown .ant-dropdown-menu, .ant-dropdown-menu-submenu .ant-dropdown-menu {
    margin-top: 15px;
}

/* table */

.ant-table-body::-webkit-scrollbar {
    display: block;
    width: 5px;
    height: 8px;
    background: #ffffff;
}

.ant-table-body::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #000099;
}

.ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected >th, .ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected >td,
.ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected:hover>th, .ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected:hover>td {
    background: #3f5163;
    color: #FFF;
}

/* checkbox */

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #000099;
    border-color: #000099;
}

.ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, .ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: #000099;
    border-color: transparent;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: #000099;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    border-color: #000099;
}

/* pagination */

.ant-pagination .ant-pagination-item-active a {
    color: #000099;
}

.ant-pagination .ant-pagination-item-active {
    border-color: #000099;
}

/* radio */

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: #000099;
    background-color: #000099;
}

.ant-radio-wrapper:hover .ant-radio-wrapper, .ant-radio-wrapper:hover .ant-radio-inner {
    border-color: #000099;
}

/* Modal */
.ant-modal .ant-modal-body {
    font-size: 34px;
}

.ant-modal .ant-modal-body .searchBox {
    font-size: 14px;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
    font-size: 20px !important;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
    font-size: 16px !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title +.ant-modal-confirm-content {
    -webkit-margin-before: 12px !important;
            margin-block-start: 12px !important;
}

/* drawer */
.ant-drawer-title {
    font-weight: bold !important;
}

/* input */
.ant-input-affix-wrapper {
    justify-content: space-between;
}

.ant-input-affix-wrapper::before {
    content: normal !important;
    content: initial !important;
}

.ant-select-selector {
    width: 100% !important;
}