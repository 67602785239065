.configBox {
    border: 1px solid #b7b7b7;
    padding: 20px;
    position: relative;
  }
  
  .configBox .title {
    position: absolute;
    top: -20px;
    left: 20px;
    padding: 10px;
    background: #eeeeee;
  }
  
  .configBox .tool-title {
    position: absolute;
    top: -15px;
    right: 20px;
  }
  
  .configDeviceContainerDetail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
  }
  
  .configDeviceContainerDetail .configDeviceContainerInput {
    display: flex;
    align-items: center;
    width: 80%;
  }
  
  .configDeviceContainerDetail .configDeviceContainerInput div {
    display: flex;
    align-items: center;
    width: 50%;
    margin-right: 20px;
  }
  
  .configDeviceContainerDetail .configDeviceContainerInput div input {
    width: 70%;
    margin-left: 10px;
  }
  
  .configDeviceContainerDetail .configDeviceContainerTool {
    display: flex;
    justify-content: flex-end;
    width: 20%;
  }
  
  .configDeviceContainerDetail .configDeviceContainerTool button {
    margin-left: 10px;
  }