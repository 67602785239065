.nav-container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    background: #3366FF;
    height: 70px;
    padding: 0 20px;
}

.nav-box {
    width: 50%;
}

.logo {
    height: 65px;
}

.userIcon {
    color: #FFF;
    margin-left: 20px; 
    padding: 11px 12px; 
    background: #99d3ff;
    border-radius: 50%;
    cursor: pointer;
}