::-webkit-scrollbar {
    display: none;
}

.filterBox {
    padding: 20px 20px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 57px -11px rgba(224,224,224,1);
    background: #FFF;
}

.searchBox {
    display: flex;
    align-items: center;
    width: 50%;
}

.searchBox svg {
    margin: 0 10px;
}

.saleSearchBox {
    width: 70%;
}

.actionBox button {
    margin-left: 10px;
}

.actionBox button svg {
    margin-right: 10px;
}

.iconOperation svg {
    margin: 0 8px;
    cursor: pointer;
    font-size: 16px;
    color: #4f5a8f;
    text-align: right;
}

.filterBoxList {
    padding: 20px 20px;
    margin-bottom: 30px;
    display: flex;
    align-items: flex-start;
    box-shadow: 0px 0px 57px -11px rgba(224, 224, 224, 1);
    -webkit-box-shadow: 0px 0px 57px -11px rgba(224,224,224,1);
    -moz-box-shadow: 0px 0px 57px -11px rgba(224,224,224,1);
    background: #FFF;
    flex-direction: column;
}